import { useMainContext } from "../context";
import { useMainQuery } from "../hooks/useMainQuery";
import { Button } from "./primitives";

import success from "./../assets/images/success.svg";

export const SuccessAnnouncement = () => {
  const { form } = useMainContext();
  const { data } = useMainQuery();

  const emailHandler = () => {
    let marian;
    Object.values(form).map((value) => {
      if (value.type === "form") {
        Object.values(value.value).map((insideValue) => {
          if (insideValue.validation.type === "email") {
            marian = insideValue.value;
            return insideValue.value;
          }
        });
      }
    });
    return marian;
  };

  return (
    <div className="successContainer">
      <div className="successHeadingContainer">
        <img className="successImg" src={success} alt="" />
        <p className="successHeading">Gotowe!</p>
      </div>
      <div className="successContent">
        <p className="successContext">
          Dziękujemy za wypełnienie formularza, potwierdzenie wraz z wynikami
          wysłaliśmy na maila {emailHandler()}
        </p>
      </div>
      <Button
        type="homepage"
        onClick={() => window.location.assign(data?.data.returnUrl!)}
      />
    </div>
  );
};
