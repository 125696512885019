import { string } from "yup";

import { Validation } from "../types/api";

export const validate = (value: string, validation?: Validation) => {
  if (validation) {
    if (validation.type === "email") {
      return string()
        .min(Number(validation.minLength))
        .max(Number(validation.maxLength))
        .email()
        .isValidSync(value);
    }
    return string()
      .min(Number(validation.minLength))
      .max(Number(validation.maxLength))
      .isValidSync(value);
  }
  return true;
};
