import PiwikPro from "@piwikpro/react-piwik-pro";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { ReactQueryDevtools } from "@tanstack/react-query-devtools";
import React from "react";

import ReactDOM from "react-dom/client";

import { App } from "./App";
import { PreloadContentWrapper } from "./components/utils/PreloadContentWrapper";
import { MainContextProvider } from "./context";

import { CookiesProvider } from "react-cookie";

import reportWebVitals from "./reportWebVitals";

const queryClient = new QueryClient({
  defaultOptions: {
    queries: { staleTime: Infinity, retry: false, refetchOnWindowFocus: false },
  },
});

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);

root.render(
  <React.StrictMode>
    <QueryClientProvider client={queryClient}>
      <PreloadContentWrapper>
        <MainContextProvider>
          <CookiesProvider>
            <App />
          </CookiesProvider>
        </MainContextProvider>
      </PreloadContentWrapper>
      <ReactQueryDevtools initialIsOpen={false} position="bottom-right" />
    </QueryClientProvider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
